<template>
  <div class="sheet-wrap">
      <a-form
          autocomplete="false"
          :label-col="{ span: 7 }"
          :wrapper-col="{ span: 17 }"
          :form="formSheet"
        >
        <div class="info-wrap">
           <InfoTitle title="班级信息" />
            <a-row :gutter="24">
              <a-col :span="8">
                <a-form-item label="科目">
                  <a-select
                    allow-clear
                      v-decorator="[
                      'subject',
                      { rules: [{ required: true, message: '请选择科目!' }] },
                    ]"
                    placeholder="科目"
                    style="width: 100%"
                    disabled
                  
                  >
                    <a-select-option value="CHINESE"> 中文 </a-select-option>
                    <a-select-option value="MATH"> 数学 </a-select-option>
                  </a-select>
                </a-form-item>
               
              </a-col>
             <a-col :span="8">
                <a-form-item label="班级名称">
                   <a-input disabled placeholder="班级名称" v-decorator="['formClassName']" />
                </a-form-item>
              </a-col>
             <a-col :span="8">
                <a-form-item label="班级类型">
                  <a-select
                    allow-clear
                      v-decorator="[
                      'classType',
                    ]"
                    placeholder="班级类型"
                    style="width: 100%"
                    disabled
                  >
                   <a-select-option :value="item.value" v-for="(item, index) in classTypeArr" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
               
              </a-col>
             
            
            </a-row>
        </div>
          <div class="info-wrap">
           <InfoTitle title="课节信息" />
            <a-row :gutter="24">
            
         
            <a-col :span="8">
            <a-form-item
              ref="name"
              label="相关课节(班级)"
            >
              <div class="shedule-period-wrapper">
                <div v-for="(item,index) in sectionTimes" :key="index">
                  {{`${item.courseSectionName}（${item.startDateTime} - ${item.endDateTime.substring(10)}）`}}</div>

                    <!-- {{
                      `${item.courseSectionName} ${moment(item.startDateTime).format('YY/MM/DD')} ${$moment(
                    item.startDateTime,
                      ).format('dddd')} ${moment(item.startDateTime).substring(
                        10,
                      )} - ${moment(item.endDateTime).substring(11)}`
                    }} -->
               
              </div>
            </a-form-item>
            </a-col>
              <a-col :span="8">
                <a-form-item label="授课老师">
                   <a-input  disabled placeholder="请填写" v-decorator="['teacherName']" />
                </a-form-item>
              </a-col>
        
            </a-row>
        </div>
       <div class="info-wrap" style="height:660px;">
           <InfoTitle title="更多信息" />
            <a-row :gutter="24">
              
           
             <a-col :span="8">
                <a-form-item label="发单有效期">
                  <a-select
                    allow-clear
                      v-decorator="[
                      'expireTime',
                      { rules: [{ required: true, message: '请选择发单有效期!' }] },
                    ]"
                    placeholder="请选择"
                    style="width: 100%"
                   
                  >
                   <a-select-option :value="item.value" v-for="(item, index) in expireTimes" :key="index">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
               
              </a-col>
              <a-col :span="8">
                <a-form-item label="发单备注">
                   <a-textarea placeholder="请输入" v-decorator="['remark']" :maxlength="255"  :rows="1" />
                </a-form-item>
               
              </a-col>
            </a-row>
          <div class="btn-wrap" style="text-align:center;padding-top:40px;">
            <a-button type="primary"  @click="sendSheet">立即发单</a-button>
          </div>
        </div>
      </a-form>
       <a-modal
        title="确认"
        :visible="visibleCancel"
        @ok="checkHoliday"
        @cancel="cancelTC"
        okText="确定"
        cancelText="放弃"
       :okButtonProps="{
        props: {
         loading:iconLoading
        },
      }"
    >
     
      <div class="issuing-wrap">
        <div style="font-size:16px;margin-bottom:8px">确定发此<span style="color:#43d186">指定单</span>吗？</div>
        <div>发单后，该授课老师将会选择同意或拒绝；授课老师处理前您可取消该单</div>
      </div>
    </a-modal>
    <!--  @ok="sendScheduleSheet"
        @cancel="cancelScheduleSheet"-->
    <a-modal
        title="操作提示"
        :visible="visibleHoliday"
         @cancel="cancelScheduleSheet"
        okText="跳过该时间，完成发单"
        cancelText="取消本次发单"
       :okButtonProps="{
        props: {
         loading:holidayLoading
        },
      }"
    >
     
      <div class="issuing-wrap">
        <div style="color:red;margin-bottom:5px">注意：您选的时间处于系统维护或假期</div>
        <div>{{messageInfo}}</div>
      </div>
       <template slot="footer">
          <a-button @click="cancelScheduleSheet">
            取消本次发单
          </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import InfoTitle from '@/components/WkInfoTitle/InfoTitle';
import moment from 'moment';
import storage from 'store';
import {createTeacherSheet,checkTeacherHoliday,getOrderAgainSheetDetail} from '@/api/schedule';

const roles = storage.get('roles');
const userInfo = storage.get('userInfo');

export default {
  name:'courseTeacherSheet',
components: { InfoTitle },
  data(){
    return{
    relationGroupId:'',
    schedulingSource:'',
    teacherCode:'',
    teacherId:'',
    teacherName:'',
    sectionIds:[],
    formClassId:'',
    sectionTimes:[],
   courseScheduleIds: [],
    scheduleOrderId:'',
    holidayLoading:false,
    visibleHoliday:false,
    messageInfo:'',
  
    form: {
        // 搜索老师表单
        beginSectionId: null,
        endSectionId: null,
        beginDate: null,
        gender: null,
        teachingStyleList: [],
        star: null,
        language: null,
        teacherLevels: [],
        dayOfWeeksCheckbox: [],
        dayOfWeeks: {},
        formClassId: this.$route.query.classId,
        teacherId: '',
        assignedTeacher: false,
        teacherName: null,
        schedulingType: null,
      },
    paramsObj:{},
    iconLoading:false,
    visibleCancel:false,

    classItem:{},
     expireTimes: [
         { label: '5分钟', value:300 },
        { label: '10分钟', value:600},
        { label: '20分钟', value:1200 },
        { label: '30分钟', value:1800 },
        { label: '1小时', value:3600 },
        { label: '2小时', value:7200 },
        { label: '3小时', value: 10800 },
        { label: '6小时', value:21600 },
        { label: '12小时', value:43200 },
        { label: '24小时', value:86400 },
        { label: '2天', value:172800 },
        { label: '3天', value:259200 },
        { label: '4天', value:345600},
        { label: '5天', value:432000 },
        { label: '6天', value:518400},
        { label: '7天', value:604800 },
      ],
       formSheet: this.$form.createForm(this),
      classTypeArr:[
        {label:'班课',value:'GROUP'},
        {label:'私教课',value:'PRIVATE'},
      ],
       
    }
  },
  methods:{
    moment,
  getOrderAgainSheetDetail(){
      let params={
        scheduleOrderId:this.scheduleOrderId
      }
      getOrderAgainSheetDetail(params).then(res=>{

      this.classItem=res.data
      this.courseScheduleIds=this.classItem.scheduleOrderRespDTO?.businessData?.courseScheduleIds;
      this.formClassId=this.classItem?.scheduleOrderRespDTO?.formClassId;
      this.sectionTimes=this.classItem?.scheduleOrderRespDTO?.businessData?.sectionTimes;
      this.sectionIds=this.classItem?.scheduleOrderRespDTO?.businessData?.sectionIds;
      this.teacherName=this.classItem?.scheduleOrderRespDTO?.teacherName;
      this.teacherId=this.classItem?.scheduleOrderRespDTO?.teacherId;
      this.teacherCode=this.classItem?.scheduleOrderRespDTO?.teacherCode;
      this.schedulingSource=this.classItem?.scheduleOrderRespDTO?.businessData?.schedulingSource;
      this.relationGroupId=this.classItem?.scheduleOrderRespDTO?.relationGroupId
          this.formSheet.setFieldsValue({
            classType: this.classItem?.scheduleOrderRespDTO?.businessData?.classType,
            formClassName: this.classItem?.scheduleOrderRespDTO?.formClassName,
            subject:this.classItem?.scheduleOrderRespDTO?.businessData?.subject,
            courseName: this.classItem?.formClassRespDTO?.courseName,
            teacherName:this.teacherName,
            remark:this.classItem?.scheduleOrderRespDTO?.businessData?.customData?.remark,
            expireTime:this.classItem?.scheduleOrderRespDTO?.expireTime

          });
      
      })
    },
cancelScheduleSheet(){
  this.visibleHoliday=false
},
  
  cancelTC(){
     this.visibleCancel=false;
  },
  //校验此排课单中包含系统不可用时间
  checkHoliday(){
    this.iconLoading=true
    let params={
      formClassId:this.formClassId,
      sectionTimes:this.sectionTimes
    }
    checkTeacherHoliday(params).then(res=>{
     
       let data=res.data;
       if(data.intersect){
         this.visibleCancel=false
           this.iconLoading=false
          // true表示有冲突，要展示messasge中内容，false不展示，调创建排课单接口
          this.visibleHoliday=true
          this.messageInfo=data.message
       }else{
          this.sendScheduleSheet()
       }
       
    }).catch((res)=>{
              this.iconLoading=false
              this.visibleCancel=false
          })
  },
    sendScheduleSheet(){
     this.holidayLoading=true
    createTeacherSheet(this.paramsObj).then(res=>{
            this.$message.success("发单成功")
             this.holidayLoading=false
              this.visibleCancel=false
                this.iconLoading=false
            //调至发单管理
            this.$router.push({path:'/workoutSchedule/issuing'})
          }).catch((res)=>{
              this.holidayLoading=false
              this.visibleCancel=false
              this.iconLoading=false
          })
    },
    sendSheet(){
       this.formSheet.validateFields((err, values) => {
        if (!err) {
          const formData = values;
          let params={
              courseScheduleIds:this.courseScheduleIds,
              expireTime:values.expireTime,
              formClassId:this.formClassId,
              remark:values.remark,
              schedulingSource:this.schedulingSource,
              sectionIds:this.sectionIds,
              sectionTimes:this.sectionTimes,
              teacherCode:this.teacherCode,
              teacherId:this.teacherId,
              teacherName:this.teacherName,
              relationGroupId:this.relationGroupId,
               relationSourceId:this.scheduleOrderId,

            }
            console.log(params)
          this.paramsObj=params
          this.visibleCancel=true;
        
        }
      });
    },
 

  
 
  },
  created(){
       window.scrollTo(0, 0); 
      
  

      
  },
  mounted(){
     let formType=this.$route.query.formType
       this.scheduleOrderId=this.$route.query.scheduleOrderId
    //发单管理，再次发单进来,回填页面信息
    if(formType==='bill'){
       //调接口去获取详情
        this.getOrderAgainSheetDetail()
      }else{
       this.classItem=JSON.parse(this.$route.query.classItem)
      this.teacherName=this.classItem.teacherName;
      this.teacherId=this.classItem.teacherId
      this.teacherCode=this.classItem.teacherCode;
      this.schedulingSource=this.classItem.schedulingSource;
      this.courseScheduleIds=this.classItem.courseScheduleIds;
           this.sectionTimes=this.classItem.sectionTimes
           this.formClassId=this.classItem.formClassId;
           this.sectionIds=this.classItem.sectionIds;
            this.formSheet.setFieldsValue({
              classType:this.classItem.classType,
              formClassName:this.classItem.formClassName,
              subject:this.$route.query.subject,
              courseName: this.classItem.courseName,
              teacherName:this.teacherName,
          });

      }
    
  }
}
</script>

<style lang="less" scoped>
.ant-form-item{
  margin-bottom: 20px;
}
.info-wrap{
  background-color: #fff;
  padding: 18px 20px 0px;
  margin-bottom: 12px;
}
.sheet-wrap{
}
.shedule-period-wrapper .ant-row {
  display: flex;
     justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.class-item{
  margin-top:9px;

}
.class-subItem{
  line-height: 24px;
}

</style>
